import { Injectable } from '@angular/core';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  userPool = new AWSCognito.CognitoUserPool(
    environment.userPoolParams.COGNITO_POOL
  );

  constructor() {}

  async isLoggedIn() {
    const token = await Storage.get({ key: 'token' });
    const bool = token.value !== null;
    return bool;
  }

  signUp(email, password) {
    return new Promise((resolved, reject) => {
      const userAttribute = [];
      userAttribute.push(
        new AWSCognito.CognitoUserAttribute({ Name: 'email', Value: email })
      );
      console.log(email, password);
      this.userPool.signUp(
        email,
        password,
        userAttribute,
        null,
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolved(result);
          }
        }
      );
    });
  }

  authenticate(email, password) {
    return new Promise((resolved, reject) => {
      const authDetails = new AWSCognito.AuthenticationDetails({
        Username: email,
        Password: password,
      });

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: this.userPool,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          resolved(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  signOut() {
    const thisUser: any = this.getCurrentUser();

    const cognitoUser = new AWSCognito.CognitoUser({
      Username: thisUser.username,
      Pool: this.userPool,
    });

    cognitoUser.signOut();
  }

  getLoggedUser() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession((err, result) => {
          if (result) {
            console.log(result);
            resolve(result);
            //resolved(result.getIdToken().getJwtToken());
          } else {
            reject(err);
          }
        });
      }
    });
  }

  async getRefreshToken() {
    const refreshToken = await Storage.get({ key: 'refreshToken' });
    return refreshToken.value;
  }

  sendRestPasswordEmail(email) {
    return new Promise((resolved, reject) => {
      let userPool = new AWSCognito.CognitoUserPool(
        environment.userPoolParams.COGNITO_POOL
      );

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: userPool,
      });

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          // successfully initiated reset password request
          resolved(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  async getCurrentUser() {
    return await this.userPool.getCurrentUser();
  }

  async getToken() {
    try {
      let token = await Storage.get({ key: 'token' });
      const expiry = JSON.parse(atob(token.value.split('.')[1])).exp;
      // If JWT is not expired return token value else refresh using refresh token.
      if (Math.floor(new Date().getTime() / 1000) < expiry) {
        return token.value;
      } else {
        const email = await Storage.get({ key: 'email' });
        await this.refreshToken(email).then(async (newTokens: any) => {
          const newIdToken = newTokens.idToken.getJwtToken();
          token = newIdToken;
          const refreshToken = newTokens.refreshToken.token;
          await Storage.set({
            key: 'token',
            value: newIdToken,
          });
          await Storage.set({
            key: 'refreshToken',
            value: refreshToken,
          });
        });
        const newToken = await Storage.get({ key: 'token' });
        return newToken.value;
      }
    } catch (e) {
      this.signOut();
    }
  }

  async refreshToken(email) {
    const refreshToken = (await this.getRefreshToken()).toString();
    return new Promise(async (resolved, reject) => {
      const token = new AWSCognito.CognitoRefreshToken({
        RefreshToken: refreshToken,
      });

      const userPool = new AWSCognito.CognitoUserPool(
        environment.userPoolParams.COGNITO_POOL
      );

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: email,
        Pool: userPool,
      });

      cognitoUser.refreshSession(token, (err, session) => {
        if (err) {
          reject(err);
        }
        resolved(session);
      });
    });
  }
}
