export const environment = {
  production: true,
  backendServiceEndpoint: 'https://aud4wktno2.execute-api.us-west-2.amazonaws.com/Prod/',
  userPoolParams: {
    REGION: 'us-west-2',
    COGNITO_POOL: {
      UserPoolId: 'us-west-2_GYzCKjgDQ',
      ClientId: 'mr8r07airjo31j0q0pir1u955',
    },
  }
};
